import React, { useRef } from "react"
import styled, { ThemeProvider } from "styled-components"
import { LightFooter } from "src/components/Footer"
import {
  Banner,
  BannerContent,
  BannerContentFullWidth,
  BannerText,
} from "src/components/Banner"
import { ClientHeader } from "src/components/Header"
import Container from "src/components/Container"
import SEO from "src/components/seo"
import { PageTitle } from "src/components/Typography"
import { rem } from "src/utils/styling"
import { darkTheme } from "src/utils/themes"
import { PageSection, SectionTitleMedium } from "src/components/PageSection"
import "./about-us.scss"
import Button from "src/components/Button"
import left_arrow from "src/images/LeftArrow.svg"
import right_arrow from "src/images/RightArrow.svg"
import dark_left_arrow from "src/images/DarkLeftArrow.svg"
import dark_right_arrow from "src/images/DarkRightArrow.svg"
import arrow from "src/images/arrow-bg.svg"
import Dell from "src/images/about_us/dell.svg"
import Dupont from "src/images/about_us/dupont.svg"
import Coke from "src/images/about_us/coke.svg"
import SadeqImage from "src/images/about_us/sadeq.png"
import DanishImage from "src/images/about_us/danish.png"
import JohnImage from "src/images/about_us/johnfox.png"
import FounderArrowRight from "src/images/about_us/founder_arrow_right.svg"
import FounderArrowLeft from "src/images/about_us/founder_arrow_left.svg"
import NycImage from "src/images/about_us/nyc.svg"
import Ubsimage from "src/images/about_us/ubs.svg"
import FrameImage from "src/images/about_us/frame.svg"
import AccentureImage from "src/images/about_us/accenture.svg"
import XeroImage from "src/images/about_us/xero.svg"
import SpencerImage from "src/images/about_us/spencer.svg"
import IBMImage from "src/images/about_us/ibm.svg"
import StefanImage from "src/images/about_us/stefan.png"
import AmanImage from "src/images/about_us/aman.png"
import RightGreyImage from "src/images/icons/right_grey.svg"
import LeftGreyImage from "src/images/icons/left_grey.svg"
import { HiChevronRight } from "react-icons/hi"
import { ReactTypeformEmbed } from "react-typeform-embed"
import { NewLandingPageHeader } from "src/components/Header"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { DEVICE_TYPE_DESKTOP, useDeviceType } from "src/utils/deviceType"
import { navigate } from "gatsby"

const TeamBanner = styled(Banner)`
  background: #fbfeff;
  padding: 80px 0 0;
  @media screen and (min-width: 1100px) {
    background: #fbfeff;
  }
`

const TeamBannerContent = styled(BannerContent)`
  padding-bottom: ${rem(20)}rem;
`

const TeamTitle = styled(PageTitle)`
  color: #000;

  > em {
    color: #34b6c3;
  }
`

const TeamBannerText = styled(BannerText)`
  color: #6f6f6f;
`

const LeftArrow = styled.img`
  width: ${rem(25)}rem;
  vertical-align: middle;
  margin-right: ${rem(10)}rem;
  margin-left: ${rem(-35)}rem;

  @media screen and (min-width: 1100px) {
    width: ${rem(90)}rem;
    margin-right: ${rem(20)}rem;
    margin-left: 0;
  }
`

const RightArrow = styled.img`
  width: ${rem(15)}rem;
  vertical-align: middle;
  margin-left: ${rem(10)}rem;
  margin-right: ${rem(-35)}rem;
  @media screen and (min-width: 1100px) {
    width: ${rem(50)}rem;
    margin-left: ${rem(20)}rem;
  }
`

const PageSectionCustom = styled(PageSection)`
  background-color: #fbfeff;
  position: relative;
  overflow: hidden;
  padding: ${rem(20)}rem 0;

  @media screen and (min-width: 1100px) {
    padding-bottom: 0;
  }
`

const HeaderButton = styled(Button)`
  display: flex;
  align-items: center;
  @media screen and (max-width: 414px) {
    font-size: 14px;
  }
`

const ContainerCustom = styled(Container)`
  overflow: hidden;
  padding-bottom: ${rem(20)}rem;
  @media screen and (min-width: 1100px) {
    padding-bottom: ${rem(40)}rem;
  }
`

const ArrowBackground = styled.div`
  position: absolute;
  width: ${rem(120)}rem;
  height: ${rem(267)}rem;
  background: url(${arrow}) no-repeat;
  background-size: contain;
  z-index: 0;
  left: ${rem(-48)}rem;
  top: ${rem(0)}rem;
  display: block;

  @media screen and (min-width: 1100px) {
    width: ${rem(262)}rem;
    height: ${rem(583)}rem;
    left: ${0}rem;
    top: ${rem(630)}rem;
  }
`

const FounderContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column-reverse;
  margin: ${rem(60)}rem 0;
  @media screen and (min-width: 1100px) {
    flex-direction: row;
    margin-top: ${rem(80)}rem;
    align-items: center;
    padding: 0 ${rem(0)}rem;
  }

  &:last-child {
    margin-bottom: ${rem(48)}rem;
    @media screen and (min-width: 1100px) {
      margin-bottom: ${rem(80)}rem;
    }
  }
`

const FounderContainerInverse = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column-reverse;
  background-color: #e5f4fd;
  border-radius: 24px;

  @media screen and (min-width: 1100px) {
    flex-direction: row-reverse;
    margin: ${rem(20)}rem ${rem(10)}rem ${rem(20)}rem;
    align-items: center;
    padding: ${rem(60)}rem ${rem(70)}rem;
    min-height: ${rem(560)}rem;
  }
`

const TextBlock = styled.div`
  @media screen and (min-width: 1100px) {
    flex: 2;
  }
`
const TextBlockSection = styled.div`
  margin: ${rem(20)}rem 0 0;
  @media screen and (min-width: 1100px) {
    margin: ${rem(30)}rem 0 0;
  }
`

const LogoBlock = styled.div`
  margin: ${rem(10)}rem 0 0;
  display: flex;
  align-items: center;

  @media screen and (min-width: 1100px) {
    margin: ${rem(30)}rem 0 0;
  }
`

const PreviousBlock = styled.div`
  display: flex;
  align-items: center;
`

const ImageBlockRight = styled.div`
  text-align: center;
  flex: 1;
  position: relative;
  @media screen and (min-width: 1100px) {
    text-align: center;
  }
`

const ImageBlockLeft = styled.div`
  text-align: center;
  flex: 1;
  position: relative;

  @media screen and (min-width: 1100px) {
    text-align: left;
    margin-right: ${rem(40)}rem;
  }
`

const LeadershipContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
  @media screen and (min-width: 1100px) {
    flex-direction: row;
    align-items: center;
  }

  &:last-child {
    margin-top: 0;
  }
`

const LeadershipContainerSection = styled.div`
  width: ${rem(420)}rem;
  min-height: ${rem(300)}rem;
  background-color: #e5f4fd;
  border-radius: ${rem(24)}rem;
  padding: ${rem(20)}rem;
  margin: 0 auto ${rem(40)}rem;
  @media screen and (min-width: 1100px) {
    width: ${rem(500)}rem;
    height: ${rem(400)}rem;
    padding: ${rem(30)}rem;
    margin: 0 ${rem(20)}rem;
  }
  @media screen and (max-width: 414px) {
    width: 100%;
    margin: 0 0 ${rem(40)}rem;
  }
`

const FeatureSection = styled.div`
  margin: ${rem(80)}rem 0;
  text-align: center;

  @media screen and (max-width: 414px) {
    margin: ${rem(40)}rem 0;
  }
`

const TeamSectionTitleMedium = styled(SectionTitleMedium)`
  color: #000;
`

const CaretImage = styled.img`
  height: ${rem(50)}rem;
  width: ${rem(50)}rem;
`

const foundingMembers = [
  {
    name: "John Fox",
    photo: JohnImage,
    previous: AccentureImage,
    study: "BS, Cellular, & Molecular Biology, University of Michigan",
    description:
      "John is a technology consulting veteran. He has successfully led the execution and turnaround of some of the most demanding technology projects ever undertaken.",
  },
  {
    name: "Sadeq Ali",
    photo: SadeqImage,
    previous: AccentureImage,
    advanceStudy: "MBA, Columbia Business School",
    study: "BE, Computer Engineering, Stevens Institute of Technology",
    description:
      "Sadeq is a serial entrepreneur with successful companies in Data Analytics, Cloud Computing, and the Future of Work. He currently lives in Surrey, UK.",
  },
  {
    name: "Danish Shamim",
    photo: DanishImage,
    previous: XeroImage,
    study: "BSs., Computer Science, University of Portsmouth",
    description:
      "Based in Australia, Danish has over 10 years of experience in software development and team leadership, delivering enterprise solutions across various industries. ",
  },
]

export const DarkSliderRightArrow = ({ className, style, onClick }) => (
  <CaretImage
    src={RightGreyImage}
    alt="arrow-right"
    className={className}
    onClick={onClick}
    style={{ ...style, zIndex: 10, right: "10px" }}
  />
)

export const DarkSliderLeftArrow = ({ className, style, onClick }) => (
  <CaretImage
    src={LeftGreyImage}
    alt="arrow-left"
    className={className}
    onClick={onClick}
    style={{ ...style, zIndex: 10, left: "10px" }}
  />
)

const FounderDesktopView = ({ item, idx }) => {
  return (
    <FounderContainerInverse>
      <TextBlock>
        <h2 className="founder__title">{item?.name}</h2>
        <TextBlockSection>
          <PreviousBlock
            style={{
              alignItems: idx === 2 ? "center" : "flex-end",
            }}
          >
            <p className="founder__text" style={{ color: "#727272" }}>
              Previous:
            </p>
            <img
              src={item.previous}
              alt={"Accenture"}
              className="founder__work-logo"
            />
          </PreviousBlock>
        </TextBlockSection>
        <TextBlockSection>
          <p className="founder__tag">{item.study}</p>
        </TextBlockSection>
        <TextBlockSection>
          <p className="founder__text">{item.description}</p>
        </TextBlockSection>
        <LogoBlock>
          <img src={NycImage} alt={"Nyc"} className="founder__logo" />
          <img src={Ubsimage} alt={"Ubs"} className="founder__logo" />
          <img src={FrameImage} alt={"Frame"} className="founder__logo" />
        </LogoBlock>
      </TextBlock>
      {item.photo && (
        <ImageBlockLeft>
          <img src={item.photo} alt={item.name} className="founder__img" />
        </ImageBlockLeft>
      )}
    </FounderContainerInverse>
  )
}

const FounderMobileView = ({ item, idx }) => {
  return (
    <LeadershipContainerSection>
      <div className="leadership__wrapper">
        <img
          src={item.photo}
          alt={"Leader"}
          className="leadership__wrapper--img"
        />
        <div>
          <h3 className="leadership__wrapper--title">{item.name}</h3>
          <div className="leadership__wrapper--previous">
            <p className="leadership__wrapper--text">Previous:</p>
            <img
              src={item.previous}
              alt={"IBM"}
              className="leadership__wrapper--worklogo"
              style={
                idx === 2
                  ? { height: "40px", width: "40px" }
                  : { marginBottom: 10 }
              }
            />
          </div>
        </div>
      </div>
      <TextBlockSection>
        <p className="leadership__text">{item.study}</p>
      </TextBlockSection>
      <TextBlockSection>
        <p className="leadership__subtext">{item.description}</p>
      </TextBlockSection>
      <LogoBlock>
        <img src={NycImage} alt={"Nyc"} className="founder__logo" />
        <img src={Ubsimage} alt={"Ubs"} className="founder__logo" />
        <img src={FrameImage} alt={"Frame"} className="founder__logo" />
      </LogoBlock>
    </LeadershipContainerSection>
  )
}

const AboutUsPage = ({ location }) => {
  const typeformRef = useRef()
  const onClickTypeform = () => {
    typeformRef.current && typeformRef.current.typeform.open()
  }
  const deviceType = useDeviceType()

  var settings = {
    dots: true,
    infinite: true,
    prevArrow: <DarkSliderLeftArrow />,
    nextArrow: <DarkSliderRightArrow />,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "center",
    centerMode: true,
    centerPadding: "80px",
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <div>
        <NewLandingPageHeader />
        <TeamBanner>
          {/* <ClientHeader /> */}

          <Container>
            <SEO
              title="About us | Meet our team"
              description="We are Accenture &amp; Thoughtworks alums that have developed delivery systems, built products at scale, and mentored talent globally for the worlds leading organizations."
              location={location}
            />
            <TeamBannerContent className="has-text-centered">
              <BannerContentFullWidth>
                <TeamTitle>
                  <LeftArrow
                    src={dark_left_arrow}
                    alt="Out-task Stories"
                    className="arrow-image-left"
                  />
                  Meet <em>our team</em>
                  <RightArrow
                    src={dark_right_arrow}
                    alt="Out-task Stories"
                    className="arrow-image-right"
                  />
                </TeamTitle>
                <TeamBannerText>
                  We are Accenture &amp; Thoughtworks alums that have developed
                  delivery systems, built products at scale, and mentored talent
                  globally for the worlds leading organizations.
                </TeamBannerText>
              </BannerContentFullWidth>
            </TeamBannerContent>
          </Container>
        </TeamBanner>
      </div>
      <div>
        <PageSectionCustom>
          <ContainerCustom>
            <TeamSectionTitleMedium>Founding Members</TeamSectionTitleMedium>
            <hr className="leadership-hr" />
            {deviceType === DEVICE_TYPE_DESKTOP ? (
              <div>
                <Slider {...settings}>
                  {foundingMembers?.map((item, idx) => (
                    <div key={idx}>
                      <FounderDesktopView item={item} idx={idx} />
                    </div>
                  ))}
                </Slider>
              </div>
            ) : (
              <div>
                {foundingMembers?.map((item, idx) => (
                  <div key={idx}>
                    <FounderMobileView item={item} idx={idx} />
                  </div>
                ))}
              </div>
            )}
          </ContainerCustom>
        </PageSectionCustom>

        <PageSectionCustom style={{ paddingTop: 0 }}>
          <ContainerCustom>
            <TeamSectionTitleMedium>Team</TeamSectionTitleMedium>
            <hr className="leadership-hr" />
            <LeadershipContainer>
              <LeadershipContainerSection>
                <div className="leadership__wrapper">
                  <img
                    src={AmanImage}
                    alt={"Leader"}
                    className="leadership__wrapper--img"
                  />
                  <div>
                    <h3 className="leadership__wrapper--title">
                      Amandeep Singh
                    </h3>
                    <p className="leadership__wrapper--subtitle">
                      Delivery Lead
                    </p>
                    <div className="leadership__wrapper--previous">
                      <p className="leadership__wrapper--text">Previous:</p>
                      <img
                        src={IBMImage}
                        alt={"IBM"}
                        className="leadership__wrapper--worklogo"
                      />
                    </div>
                  </div>
                </div>

                <TextBlockSection>
                  <p className="leadership__text">B.E., Computer Science</p>
                </TextBlockSection>
                <TextBlockSection>
                  <p className="leadership__subtext">
                    Aman is a seasoned delivery manager with a proven track
                    record of successfully delivering projects across diverse
                    tech domains, including blockchain and healthtech products.
                    He is currently based in India.
                  </p>
                </TextBlockSection>
              </LeadershipContainerSection>
              <LeadershipContainerSection>
                <div className="leadership__wrapper">
                  <img
                    src={StefanImage}
                    alt={"Leader"}
                    className="leadership__wrapper--img"
                  />
                  <div>
                    <h3 className="leadership__wrapper--title">
                      Stefan Makowski
                    </h3>
                    <p className="leadership__wrapper--subtitle">
                      Developer & Partner Success Advocate
                    </p>
                    <div className="leadership__wrapper--previous">
                      <p className="leadership__wrapper--text">Previous:</p>
                      <img
                        src={SpencerImage}
                        alt={"IBM"}
                        className="leadership__wrapper--worklogo"
                      />
                    </div>
                  </div>
                </div>
                <TextBlockSection>
                  <p className="leadership__text">
                    Business Marketing and Management 
                  </p>
                  <p className="leadership__text">
                    University of Nottingham Trent 
                  </p>
                </TextBlockSection>
                <TextBlockSection>
                  <p className="leadership__subtext">
                    Stefan excels in enhancing partner relationships through
                    tailored solutions and exceptional client support. He is
                    based in London, UK.
                  </p>
                </TextBlockSection>
              </LeadershipContainerSection>
            </LeadershipContainer>
          </ContainerCustom>
        </PageSectionCustom>

        <PageSectionCustom style={{ paddingTop: `${rem(40)}rem` }}>
          <ContainerCustom>
            <hr className="feature-hr" />
            <h1 className="feature-title">
              Leverage our diverse offerings to jumpstart your development team
              and <span>build powerful digital products.</span>
            </h1>
            <FeatureSection>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <HeaderButton
                  type="solid"
                  onClick={() => navigate("/offerings/product-development")}
                >
                  View Offerings
                  <HiChevronRight size={22} />
                </HeaderButton>
              </div>
            </FeatureSection>
          </ContainerCustom>
        </PageSectionCustom>
        <LightFooter />
      </div>
    </ThemeProvider>
  )
}

export default AboutUsPage
